import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { NOTIFICATION_TYPES } from "../components/Notifications";
import app from "../config/axiosConfig";
import { auth, db, signOut, storage } from "../config/firebase.js";
import { queryClient } from "../context/Providers";
import { errorLogger } from "../hooks/useErrorLogger";
import { store } from "./store.js";
import { initialise } from "./userRedux/userSlice.js";

export const APP_URL = import.meta.env.VITE_API_URL;

//TODO - change name
export const signInOrLogin = async (user, dispatch, saveData) => {
  try {
    const response = await app.post("signin-or-login", user);

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const data = response.data;
    dispatch(saveData(data));
  } catch (error) {
    console.log(error);
    alert(error.message);
  }
};

export const initaliseUser = async () => {
  try {
    const data = await fetchUserData();
    if (data != null) {
      const { authData, userData } = data;
      store.dispatch(initialise({ authData, userData }));
    } else {
      store.dispatch(initialise());
    }
  } catch (error) {
    store.dispatch(initialise());
  }
};

export const registerEmployer = async (email, password, employerData) => {
  try {
    //TODO - move it to the backend

    if (!email || email.trim() === "") return;
    const _email = email.trim().toLowerCase();

    const credentials = await createUserWithEmailAndPassword(
      auth,
      _email,
      password
    );

    let logoUrl = null;

    try {
      const firmLogo = employerData?.firmLogo[0] ?? null;

      if (firmLogo != null) {
        const storageRef = ref(
          storage,
          `/firmLogos/${firmLogo.size}${firmLogo.name}`
        );

        await uploadBytes(storageRef, firmLogo);

        logoUrl = await getDownloadURL(storageRef);
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
      errorLogger(error);
    }

    const employer = {
      name: employerData.firstName,
      email: email,
      surname: employerData.lastName,
      phone: employerData.phoneNumber,
      role: employerData.role,
      companyName: employerData.firmName,
      firmFounded: employerData.firmFoundedYear,
      firmLogo: logoUrl,
      firmLocation: employerData.firmPrimaryLocation,
      selectedLocations: employerData.firmOtherLocations,
      firmWebsite: employerData.firmUrl,
      firmSize: employerData.firmSize,
      aboutCompany: employerData.firmDescription,
      isEmployer: true,
      uid: credentials.user.uid,
    };

    await setDoc(doc(db, "employers", credentials.user.uid), employer);

    //we need to sign out the user after registration because createUserWithEmailAndPassword automatically signs in the user
    signOut(auth);

    return employer;
  } catch (error) {
    console.error("Error registering employer:", error);
    errorLogger(error);
    throw error;
  }
};

export const verifyEmployer = async (employerData) => {
  try {
    const response = await app.post("api/verify-employer", {
      employerData,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error verifying employer:", error);
    throw error;
  }
};

const fetchUserData = async () => {
  const response = await app.get("users/me");
  return response.data;
};

export const editUser = async (user, dispatch, saveData) => {
  try {
    const response = await app.post("edit-user", { user });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }

    const updatedUser = response.data;
    dispatch(saveData(updatedUser));

    console.log("User edited successfully");
  } catch (error) {
    console.error("Error editing user:", error);
    throw error;
  }
};

export const createJob = async (jobData) => {
  try {
    const employerData = store.getState().data.value;
    const user = store.getState().user.userData;

    const response = await app.post("create-job", {
      jobData,
      user,
      employerData,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error creating job:", error);
    throw error;
  }
};

export const jobApply = async (
  job_id,
  user_id,
  cv,
  coverLetter,
  transcripts,
  employer_id
) => {
  try {
    const response = await app.post("job-apply", {
      job_id,
      user_id,
      cv,
      coverLetter,
      transcripts,
      employer_id,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    sendNotification(
      employer_id,
      user_id,
      job_id,
      NOTIFICATION_TYPES.APPLY_JOB
    );
  } catch (error) {
    console.error("Error applying for job:", error);
    throw new Error("Error applying for job");
  }
};

export const fetchAndCreateConversation = async (
  startedChatById,
  candidateId,
  employerId,
  jobId
) => {
  try {
    const response = await app.post("fetch-create-conversation", {
      startedChatById,
      candidateId,
      employerId,
      jobId,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
    const conversationData = response.data;
    return conversationData;
  } catch (error) {
    console.error("Error fetching/creating conversation:", error);
    throw new Error("Error fetching/creating conversation");
  }
};

export const sendNotificationEmail = async (userId, message) => {
  try {
    const response = await app.post("api/send-notification-email", {
      userId,
      message,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error sending notification email:", error);
    throw error;
  }
};

//TODO: Move to the backend
export const sendNotification = async (userId, reference, jobId, type) => {
  try {
    const notificationId = uuidv4();
    const notification = {
      notificationId,
      userId,
      reference,
      jobId,
      type,
      date: new Date().toISOString(),
      read: false,
    };

    await setDoc(doc(db, "notifications", notificationId), notification);
    await queryClient.invalidateQueries({ queryKey: ["notifications"] });
    await sendNotificationEmail(userId, "You have a new notification!");
  } catch (error) {
    console.log(error);
  }
};
