import { useMutation, useQueryClient } from "@tanstack/react-query";
import app from "../../config/axiosConfig";
// @ts-ignore
import { sendNotification } from "../../app/functions";
// @ts-ignore
import { NOTIFICATION_TYPES } from "../../components/Notifications";

const _likeJob = async ({
  jobId,
  userId,
  jobUserId,
  queryObject = {},
}: {
  jobId: string;
  userId: string;
  jobUserId: string;
  queryObject?: any;
}) => {
  const response = await app.put(`api/like-job`, {
    jobId: jobId,
    userId: userId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking job: " + response.statusText);
  }
  return response.data;
};

const useLikeJob = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: likeJob } = useMutation({
    mutationFn: _likeJob,
    onMutate: async (data) => {
      let queryKey = ["jobs", data.queryObject];

      if (data.queryObject == null) {
        queryKey = ["jobs"];
      }

      await queryClient.cancelQueries({ queryKey });

      const previousJobs: any[] = queryClient.getQueryData(queryKey) ?? [];

      const job = previousJobs?.find((job: any) => job?.uid === data.jobId);

      if (job == null) return { previousJobs };

      const updatedJobs = previousJobs.filter(
        (job: any) => job?.uid !== data.jobId
      );

      queryClient.setQueryData(["jobs", data.queryObject], updatedJobs);

      return { previousJobs };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(
        ["jobs", data.queryObject],
        context?.previousJobs
      );
    },

    onSuccess: async (data, variables) => {
      if (data.isMatch) {
        await Promise.all([
          sendNotification(
            variables.userId,
            variables.jobId,
            variables.jobId,
            NOTIFICATION_TYPES.MATCH_CANDIDATE
          ),
          sendNotification(
            variables.jobUserId,
            variables.userId,
            variables.jobId,
            NOTIFICATION_TYPES.MATCH_JOB
          ),
        ]);
      }
      if (!data.isMatch && data.isLiked) {
        sendNotification(
          variables.jobUserId,
          variables.userId,
          variables.jobId,
          NOTIFICATION_TYPES.LIKE_JOB
        );
      }
    },

    onSettled: () => {
      if (queryClient.isMutating({ mutationKey: ["jobs"] }) === 1) {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      }
      queryClient.invalidateQueries({
        queryKey: ["jobs", { type: "my-matches-stats" }],
      });
    },
  });

  return {
    isLoading: isUpdating,
    likeJob,
  };
};

export default useLikeJob;
