import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiCaretCircleLeftFill, PiSuitcaseLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { sendNotification } from "../../app/functions";
import harvey from "../../assets/example_images/harvey.jpg";
import ExperienceList from "../../components/ExperienceList/ExperienceList.jsx";
import WhichJobModal from "../../components/modals/WhichJobModal.jsx";
import { NOTIFICATION_TYPES } from "../../components/Notifications";
import { IMAGES } from "../../constants/images";
import useCandidate from "../../hooks/candidates/useCandidate";
import useLikeCandidate from "../../hooks/candidates/useLikeCandidate";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import useCheckIfAnonymous from "../../hooks/useCheckIfAnonymous.js";
import useNavigateToConversation from "../../hooks/useNavigateToConversation.js";
import { experienceTotal } from "../../utils/helpers.js";
import styles from "./RecruiterCandidatePagePreview.module.css";
import useInteractionHistory from "../../hooks/notifications/useInteractionHistory";
import HistoryModal from "../../components/modals/HistoryModal/HistoryModal";
import { MdRemoveRedEye } from "react-icons/md";

const areAnyJobsMarked = (jobs, savedJobs) =>
  jobs?.some((job) => savedJobs?.includes(job?.uid));

const isJobMarked = (jobId, savedJobs) =>
  jobId != null && savedJobs?.includes(jobId);

const RecruiterCandidatePagePreview = () => {
  const navigate = useNavigate();
  const { navigateToConversation } = useNavigateToConversation();
  const { userUid, jobUid } = useParams();
  const { history } = useInteractionHistory(userUid);
  const userData = useSelector((state) => state.user.userData);
  const { state: routingState } = useLocation();
  const { myJobs } = useMyJobs();
  const { likeCandidate } = useLikeCandidate();
  const { incognito, loading } = useCheckIfAnonymous(
    userData.uid,
    userUid ?? userData.uid,
    jobUid
  );
  const { candidate, isCandidateLoading, refetch } = useCandidate(
    userUid ?? userData.uid,
    {
      jobId: jobUid,
    }
  );

  const [candidateToBeLiked, setCandidateToBeLiked] = useState(null);
  const [marked, setMarked] = useState(
    jobUid != null
      ? isJobMarked(jobUid, candidate?.saved)
      : areAnyJobsMarked(myJobs, candidate?.saved)
  );
  const [candidateToBeMessaged, setCandidateToBeMessaged] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const user = candidate;

  const flipDateString = (dateString) => {
    const parts = dateString?.split("-");
    const flippedDateString = parts?.reverse().join("-");
    return flippedDateString;
  };

  const handleMessage = (candidateId) => {
    setCandidateToBeMessaged(candidateId);
  };

  const handleGoBack = () => {
    if (routingState?.conversationId) {
      navigate("/chat-room", {
        state: { conversationId: routingState.conversationId },
      });
    } else if (routingState?.jobId) {
      navigate(`/my-job/${routingState.jobId}`, {
        state: { defaultTab: routingState.jobTab },
      });
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    setMarked(
      jobUid != null
        ? isJobMarked(jobUid, candidate?.saved)
        : areAnyJobsMarked(myJobs, candidate?.saved)
    );
  }, [candidate, jobUid, myJobs]);

  if (isCandidateLoading || loading) {
    return <DotLoader color="#868bff" />;
  }

  const isIncognito = incognito;

  return (
    <div className={styles.container}>
      <div
        className={
          (styles.contentsection,
          "flex flex-row items-center justify-start gap-2 w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col flex-row items-center">
          <Button
            variant="outlined"
            className="flex items-center border-none p-0"
            onClick={handleGoBack}
          >
            {userData.isEmployer ? (
              <span className="flex flex-row gap-2 items-center">
                <PiCaretCircleLeftFill
                  size={38}
                  className="text-[#46D6EA] duration-75 hover:scale-125"
                />
                Back
              </span>
            ) : (
              <span className="flex flex-row gap-2 items-center">
                <img src={IMAGES.EXIT} width={20} height={20} />
                Exit anonymous mode
              </span>
            )}
          </Button>
        </div>
      </div>
      <div
        className={
          (styles.contentsection,
          "flex flex-col md:flex-row items-start justify-center w-[90%] lg:w-[80%] mt-5 gap-4")
        }
      >
        <div className="flex flex-col gap-4 w-full md:max-w-[458px]">
          <div className={styles.topbar}>
            <img
              src={user?.profileImg ? user.profileImg : harvey}
              alt="user_image"
              className={styles.imgprofile}
            />
            <div className={styles.widesection}>
              <div className="flex justify-between">
                <div className="flex flex-col items-center justify-center gap-4">
                  <p className="font-bold text-xl text-white">
                    {user?.name === "" || !user?.name ? (
                      <>Anonymous</>
                    ) : (
                      <>
                        {user?.name} {user?.surname}
                      </>
                    )}
                  </p>

                  <div className={styles.greenbtn}>
                    <div className={styles.thicktextbtn}>
                      •{" "}
                      {user?.searchingStatus
                        ? user.searchingStatus === "actively looking"
                          ? "Actively looking"
                          : "Casually exploring"
                        : "Casually exploring"}
                    </div>
                  </div>
                  {history?.length > 0 && (
                    <div className="flex flex-row items-center justify-start gap-x-4 w-full mb-2">
                      <p className="italic text-sm text-white-600">
                        You have interacted with this candidate before
                      </p>
                      <MdRemoveRedEye
                        className="text-white-600 cursor-pointer"
                        size={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowHistoryModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.topbarsection}>
                {!user?.cities == null || user?.cities.length === 0 ? (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    Candidate location not added
                  </p>
                ) : user?.cities.length > 0 ? (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />

                    {user?.cities.join(", ")}
                  </p>
                ) : (
                  <p className="flex flex-row gap-y-1  items-center smallText">
                    <CiLocationOn size={20} className="mr-2" />
                    No location added
                  </p>
                )}

                <div className="flex flex-row gap-y-1 items-center smallText">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {user?.expertise ? (
                    <>{user?.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </div>
                <div className="flex flex-row gap-y-1 items-center smallText">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span>{experienceTotal(user)}</span>
                </div>
                <div className="flex flex-row gap-y-1 items-center smallText">
                  <BiMessage size={20} className="mr-2" />
                  {user?.selectedLanguages?.[0] ? (
                    <>{user?.selectedLanguages?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center mt-3 gap-2 w-full">
                {userData.isEmployer && (
                  <Button
                    className="flex flex-1 w-full p-0"
                    onClick={() => setCandidateToBeLiked(user)}
                  >
                    <img
                      src={
                        marked ? IMAGES.GREEN_HEART_FILLED : IMAGES.GREEN_HEART
                      }
                      width={15}
                      height={14}
                    />
                    {marked ? "Liked" : "Like"}
                  </Button>
                )}
                {userData.isEmployer && (
                  <Button
                    variant="outlined"
                    className="text-white border-white flex flex-1 w-full p-0"
                    onClick={() => handleMessage(user.uid)}
                  >
                    <img src={IMAGES.MESSAGE_WHITE} width={18} height={18} />
                    Send message
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={"flex flex-col gap-4"}>
            <div className={styles.block}>
              <h2 className={styles.titletext}>Skills</h2>
              <div className="flex flex-col flex-wrap gap-2">
                {user?.skills?.length > 0 ? (
                  user?.skills?.map((skill, i) => (
                    <button className={styles.buttonSkill} key={i}>
                      <img src={IMAGES.CHECK_MARK} width={15} height={14} />
                      {skill}
                    </button>
                  ))
                ) : (
                  <>No skills added</>
                )}
              </div>
            </div>

            <div className={styles.block}>
              <div className="flex items-center justify-between">
                <h2 className={styles.titletext}>Documents</h2>
              </div>
              <div className="flex items-center justify-center">
                {isIncognito ? (
                  <img src={IMAGES.LOCK} alt="Incognito" />
                ) : (
                  <div className="flex flex-col w-full gap-4">
                    <div>
                      {user?.cv != null && user?.cv !== "" ? (
                        <>
                          <div className={styles.cv_element}>
                            <div className="flex gap-4 text-sm font-medium">
                              <img
                                src={IMAGES.ATTACHMENT}
                                width={15}
                                height={14}
                                alt="attachment"
                              />
                              {user.name}-CV
                            </div>
                            <a
                              className={styles.buttonView}
                              href={user.cv}
                              target="_blank"
                            >
                              <img
                                src={IMAGES.ATTACHMENT_VIEW}
                                width={15}
                                height={15}
                                alt="attachment"
                              />
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-2 text-white">
                            No CV is uploaded
                          </div>
                        </>
                      )}
                    </div>
                    {/* Transcript */}
                    <div>
                      {user?.transcripts != null &&
                      user?.transcripts !== "" &&
                      user.transcripts.length > 0 ? (
                        <>
                          {user.transcripts?.map((path, index) => (
                            <div className={styles.cv_element} key={index}>
                              <div className="flex gap-4 text-sm font-medium">
                                <img
                                  src={IMAGES.ATTACHMENT}
                                  width={15}
                                  height={14}
                                />
                                {user?.name}-Transcript-{index + 1}
                              </div>
                              <a
                                className={styles.buttonView}
                                href={path}
                                target="_blank"
                              >
                                <img
                                  src={IMAGES.ATTACHMENT_VIEW}
                                  width={15}
                                  height={15}
                                />
                              </a>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="mt-2 text-white">
                            No Academic Transcripts are uploaded
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Medium section */}
        <div
          className={
            (styles.personalmidsection, "flex flex-col lg:flex-row w-full ")
          }
        >
          <div className={styles.largesection}>
            {/* About me section */}
            <div className={styles.abtme}>
              <div className="flex justify-between mb-3 mt-3">
                <h2 className={styles.titletext}>About the Candidate</h2>
              </div>
              {user?.description ? (
                user?.description.split("\n").map((str, index) => (
                  <p className="text-white mb-2" key={index}>
                    {str}
                  </p>
                ))
              ) : (
                <>
                  <p className="text-white">No description is added</p>
                </>
              )}
            </div>
            {/* My Experience section */}
            <div className={styles.abtme}>
              <div className="flex items-center justify-between mb-3">
                <h2 className={styles.titletext}>Experience</h2>
              </div>
              {user?.employmentHistory && (
                <ExperienceList
                  experiences={user.employmentHistory}
                  isIncognito={isIncognito}
                />
              )}
            </div>
            {/* my Education */}
            <div className={"flex flex-col gap-4 pt-[24px] border-none"}>
              <div className="flex justify-between">
                <h2 className={styles.titletext}>Candidate's Education</h2>
              </div>
              <div>
                {user?.master && user.master.length > 0 ? (
                  <>
                    <p className="text-white  mt-2 font-bold">
                      Master of Laws (LL.M.) Certificates
                    </p>
                    {user.master?.map((item, index) => (
                      <div className="flex items-center" key={index}>
                        <ul className="px-4 list-disc">
                          <li className="text-white">{item}</li>
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="text-white">No LL.M. added</p>
                  </>
                )}
              </div>
              <div>
                {user?.university ? (
                  <>
                    <div className="flex flex-col items-start  justify-between">
                      <p className="text-white">
                        University Name: {user.university}
                      </p>
                      <p className="text-white"> - Grades: {user.grades}</p>
                      <p className="text-white">
                        - Graduation date:{" "}
                        {flipDateString(user.dateOfGradutation)}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-white">No Education is added </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhichJobModal
        show={Boolean(candidateToBeMessaged)}
        onClose={() => setCandidateToBeMessaged(null)}
        onJobSelect={(job) => {
          navigateToConversation(candidateToBeMessaged, job.uid);
        }}
      >
        Select the job you want to message the candidate about:
      </WhichJobModal>
      <WhichJobModal
        show={Boolean(candidateToBeLiked)}
        candidate={candidateToBeLiked}
        onClose={() => setCandidateToBeLiked(null)}
        onJobSelect={async (job) => {
          likeCandidate(
            { candidateId: candidateToBeLiked.uid, jobId: job.uid },
            {
              onSuccess: (newData) => {
                setMarked(!marked);
                if (!candidateToBeLiked.saved.includes(job.uid)) {
                  if (newData.isMatch) {
                    sendNotification(
                      job.userid,
                      candidateToBeLiked.uid,
                      job.uid,
                      NOTIFICATION_TYPES.MATCH_JOB
                    );
                    sendNotification(
                      candidateToBeLiked.uid,
                      job.uid,
                      job.uid,
                      NOTIFICATION_TYPES.MATCH_CANDIDATE
                    );
                  } else {
                    sendNotification(
                      candidateToBeLiked.uid,
                      job.uid,
                      job.uid,
                      NOTIFICATION_TYPES.LIKE_CANDIDATE
                    );
                  }
                }
                refetch();
              },
            }
          );
        }}
      >
        Select the job you want to like the candidate for:
      </WhichJobModal>
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        history={history ?? []}
      />
    </div>
  );
};

export default RecruiterCandidatePagePreview;
