import { Modal } from "react-bootstrap";
import styles from "./HistoryModal.module.css";
import { formatDistance, format, differenceInDays } from "date-fns";

interface HistoryModalProps {
  history: { date: string; event: string }[];
  show: boolean;
  onHide: () => void;
}

function formatDistanceOrExactDate(eventDate: Date, currentDate: Date) {
  const daysDifference = differenceInDays(eventDate, currentDate);

  if (Math.abs(daysDifference) >= 1) {
    return format(eventDate, "MMM dd, yyyy, HH:mm");
  }

  return formatDistance(eventDate, currentDate, { addSuffix: true });
}

const HistoryModal = ({ show, onHide, history }: HistoryModalProps) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">Interaction History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {history.map((item, index) => {
          return (
            <div key={index} className={styles.item}>
              <p className={styles.itemTitle}>{item.event}</p>
              <p className={styles.itemDate}>
                {formatDistanceOrExactDate(new Date(item.date), new Date())}
              </p>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default HistoryModal;
