import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";
import qs from "qs";

const _getCandidate = async (userId: string, queryObject?: {}) => {
  const query = qs.stringify({
    ...queryObject,
  });

  const response = await app.get(`api/candidates/${userId}?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching candidate: " + response.statusText);
  }
  return response.data;
};

const useCandidate = (userId: string, query?: {}, enabled = true) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["candidate", userId, query],
    queryFn: () => _getCandidate(userId, query),
    enabled: enabled,
  });

  return {
    candidate: data,
    error,
    isCandidateLoading: isLoading,
    refetch,
  };
};

export default useCandidate;
